export default {
  invalidUserRolException: "Rol d'usuario invàlid",
  roleEntityException: "Rol d'usuario invàlid",
  roleIssuerException: "Rol d'usuario invàlid",
  invalidUuidFormatException: "Error de format a l'uuid",
  invalidISOFormatException: 'Error de format a la data',
  invalidEmailFormatException: "Error de format a l'email",
  InvalidLevelOfAssuranceException: 'Nivell de garantia no suportat',
  InvalidFieldPathException: 'Ruta de camp invàlida',
  InvalidCredentialSubjectValue:
    "La propietat de l'assumpte de la credencial proporcionada no coincideix amb la sol·licitada",
  InvalidCredentialSubjectProperty:
    "No es troba la propietat a l'assumpte de la credencial",
  DidNotFoundInJwt: 'DID no trobat al JWT',
  InternalServerCustomError: 'Error intern',
  CredentialIdNotFoundException: 'Ordre de credencial no trobada',
  CredentialOrderIdNotFoundException: 'Ordre de credencial no trobada',
  CredentialOrdersByFilterNotFoundException:
    'No hi ha credencials que coincideixin amb la teva cerca',
  CredentialOrderNotFoundException: "No s'ha trobat cap ordre de credencial",
  AuthServiceValidationException: "Accés d'entitat no autoritzat",
  CredentialDuplicatedException: 'La credencial ja ha sigut emesa anteriorment',
  CredentialPayloadValidatorException:
    'Error validant el contingut de la credencial',
  EntityDidNotValidException: "DID de l'entitat invàlid",
  frontend_error_000: "Error autenticant l'usuari: ",
  frontend_error_001: "Error obtenint l'ordre de credencial: ",
  frontend_error_002: 'Error obtenint les credencials',
  frontend_error_003: 'Error en emetre la credencial: ',
  frontend_error_004: 'Error obtenint els tipus de les credencials: ',
  frontend_error_005: 'Error obtenint els nivells de garantia: ',
  frontend_error_006: 'Error obtenint el nivell de garantia: ',
  frontend_error_007: 'Error obtenint les entitats',
  frontend_error_008: "Error en reenviar l'email",
  login: {
    control:
      'Controla i emet credencials verificables a qualsevol moment i qualsevol lloc',
    with_vidchain:
      "Amb VIDidentity oferim solucions d'identitat segura que permeten una privacitat, drets i llibertats a les persones d'una manera més digital i sostenible.",
    login_with: 'Inicia sessió amb CaminsWallet',
    more_info: 'Més info sobre CaminsWallet',
    successful: "L'inici de sessió ha sigut exitós",
    failed: "L'inici de sessió ha fallat",
    redirected: "Seràs redirigit a la pantalla d'inici",
    try_again:
      "Si us plau, torna-ho a provar o contacta amb l'administrador de la teva plataforma.",
    continueIssuance:
      "Fes clic al botó d'inici de sessió per continuar amb l'emissió de la credencial",
    redirecting: "Redirigint per a l'autenticació…",
    get_it: 'Aconsegueix-la gratuïtament al teu telèfon',
  },
  callback: {
    success: "El procés d'emissió de la credencial ha sigut exitós",
    mobile_wallet:
      'Credencial emesa. Obre el CaminsWallet per rebre la credencial',
    go_wallet: 'Pots trobar la teva credencial a la teva cartera.',
    open_wallet: 'Obrir CaminsWallet',
    failed: "El procés d'emissió de la credencial ha fallat",
    error_details: "Detalls de l'error:",
  },
  actions: {
    cancel: 'Cancel·lar',
    filter: 'Filtrar',
    accept: 'Acceptar',
    success: 'Èxit',
    confirm: 'Confirmar',
    search: 'Buscar',
    create: 'Crear',
    revoke: 'Revocar',
    resend: 'Reenviar',
    edit: 'Editar',
    close: 'Tancar',
    save: 'Guardar',
    approve: 'Aprovar',
    reject: 'Rebutjar',
    sendToSign: 'Enviar a firmar',
    ok: 'OK',
  },
  form: {
    fill: 'Completa el formulari',
    receiverEmail: 'Email del receptor',
    identityVerification: "Mètode de verificació d'identitat",
    loadingCredential: 'Carregant tipus de la credencial…',
    loadingIdentity: "Carregant dades de verificació d'identitat…",
    createNewUser: 'Crear nou usuari',
    fillfield: "Has d'omplir aquest camp",
    provideValidEmail: 'Has de prover un email vàlid',
    provideValidNumber: 'Has de proveir un número personal vàlid',
    provideValidURL: 'Has de proveir una URL vàlida',
    provideValidDate: 'Has de proveir una data vàlida',
    credentialCreated:
      "Ordre de credencial creada amb èxit. L'usuari rebrà un correu electrònic per obtenir la credencial",
    credentialF2FCreated:
      'Ordre de credencial creada amb èxit. Si us plau, firma el document al dispositiu %%%%% per finalitzar el procés',
    credentialSubject: 'Assumpte de la credencial',
    language: 'Idioma',
    chooseLanguage: 'Selecciona un idioma…',
    credentialUpdated: 'Sol·licitud de credencial actualitzada amb èxit',
    userLanguage: "Idioma de l'usuari",
  },
  language: {
    spanish: 'Espanyol',
    english: 'Anglès',
  },
  filters: {
    any: 'Qualsevol atribut a la credencial',
    credentialType: 'Tipus de credencial',
    startDate: 'Data inicial',
    endDate: 'Data final',
    actionName: "Nom de l'acció",
    entityName: "Nom de l'entitat",
    selectEntity: 'Selecciona una entitat',
    searchText: 'Text de cerca',
    types: 'Tipus',
    status: 'Estat',
  },
  table: {
    creationDate: 'Data de creació',
    notAvailable: 'N/A',
    empty: 'Cap registre a mostrar',
    sureRevoke: 'Estàs segur/a que vols revocar aquesta credencial?',
    sureReject:
      'Estàs segur/a que vols rebutjar aquesta sol·licitud de credencial?',
    agentName: "Nom d'agent",
    date: 'Data',
    status: 'Estat',
    viewDetails: 'Veure detalls',
    revokeCredential: 'Revocar credencial',
    resendEmail: 'Reenviar correu',
    ivmStatus: "Estat de verificació d'identitat",
    expirationDate: "Data d'expiració",
    rowsPerPage: 'Files per pàgina:',
    of: 'de',
    more: 'més de',
  },
  menu: {
    credentials: 'Gestió de credencials',
    issueCredentials: 'Emetre credencials',
    user: "Gestió d'usuari",
    auditTrails: 'Auditoria',
    credentialRequests: 'Sol·licituds de credencial',
  },
  details: {
    attachments: 'Adjunts',
    auditTrailsDetails: "Detalls de l'Auditoria",
    name: 'Nom',
    eventName: "Nom de l'esdeveniment",
    operatorName: "Nom de l'agent",
    entity: 'Entitat',
    operator: 'Agent',
    role: 'Rol',
    legalId: 'Identificador legal',
    action: 'Acció',
    payload: 'Contingut',
    emailResentSuccess: "El correu s'ha reenviat amb èxit",
    revokeSuccess: 'Credencial revocada amb èxit',
    rejectSuccess: 'Sol·licitud de credencial rebutjada',
    credentialDetails: 'Detalls de la credencial',
    userDetails: "Detalls de l'usuari",
    requestDetails: 'Detalls de sol·licitud',
    credentialRequestDetails: 'Sol·licitud de credencial',
    credentialOrderId: "ID de l'Ordre de credencial",
    credentialIssuanceId: "ID d'emissió de credencial",
    type: 'Tipus de credencial',
    types: 'Tipus',
    issuer: 'Emissor',
    holder: 'Posseïdor',
    createdAt: 'Creada',
    issuedAt: 'Emesa',
    id: 'ID',
    test: 'Prova',
    receiverEmail: 'Email del receptor',
    identityVerificationMethod: "Mètode de verificació d'identitat",
    faceToFace: 'Presencial',
    next: 'Següent',
    previous: 'Anterior',
    stillNotSigned:
      "El document encara no ha sigut firmat. Firma el document al dispositiu i fes clic a 'Següent'.",
    credentialIssuanceDocumentSignature:
      "Firma del document de la sol·licitud d'emissió de la credencial",
    selectDevice:
      'Selecciona un dispositiu al qual enviar el document a firmar:',
    signedDocument: "S'ha enviat un email a l'usuari per finalitzar el procés",
    docHasBeenSent: "Firma el document al dispositiu i fes clic a 'Següent'.",
    docHasBeenRejected: 'El document ha sigut rebutjat',
    sent: 'Document enviat',
    signed: 'Document firmat',
    credentialOrderCreatedFromRequest:
      'La teva sol·licitud de credencial ha sigut aprovada. Ves al llistat de credencials.',
    credentialRequestApproved: 'Sol·licitud de credencial aprovada',
  },
  requestDetails: {
    credentialRequestId: 'ID de sol·licitud de credencial',
    type: 'Tipus de credencial',
    receiverEmail: 'Email del receptor',
    requestedAt: 'Sol·licitada',
    identityVerificationMethod: "Mètode de verificació d'identitat",
    F2F: 'Presencial',
    userDetails: "Detalls de l'usuari",
    EmailCredential: "Credencial d'email",
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Nom i cognoms',
    userLegalId: "Número d'Identitat",
  },
  status: {
    CREATED: 'Creat',
    ISSUED: 'Emès',
    REVOKED: 'Revocat',
    REVOCATION_REQUESTED: 'Revocació sol·licitada',
    undefined: 'Indefinit',
    REQUESTED: 'Sol·licitat',
    VERIFIED: 'Verificat',
    UNVERIFIED: 'No verificat',
    ISSUE_REQUESTED: 'Emissió sol·licitada',
    REVOCATION_FAILED: 'Revocació fallida',
  },
  requestStatus: {
    REQUESTED: 'Sol·licitat',
    APPROVED: 'Aprovada',
  },
  identityVerificationStatus: {
    CREATED: 'Creat',
    STARTED: 'Iniciat',
    DOCUMENT_REJECTED: 'Document rebutjat',
    DOCUMENT_SENT: 'Document enviat',
    FINISHED: 'Finalitzat',
  },
  auditTrailsActions: {
    UserLogin: 'Inici de sessió',
    CredentialOrderCreated: 'Ordre de credencial creada',
    ResendCredentialOrderEmail: 'Correu de sol·licitud de credencial reenviat',
    CredentialHolderAuthenticated: 'Receptor de la credencial autenticat',
    CredentialIssued: 'Credencial emesa',
    CredentialRevocationRequested: 'Revocació de credencial sol·licitada',
    CredentialRevoked: 'Credencial revocada',
    CredentialRequestCreated: 'Sol·licitud de credencial creada',
    CredentialOrderRequestCreated: "Sol·licitud d'ordre de credencial creada",
    CredentialRequestConfirmed: 'Sol·licitud de credencial confirmada',
    CredentialRequestDocumentSigned:
      'Document de la sol·licitud de credencial firmat',
    CredentialOrderRequestDeleted: 'Sol·licitud de credencial eliminada',
    PresentationCreated: 'Presentació creada',
  },
};
