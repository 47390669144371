import React, { useContext } from 'react';
import './Credentials.css';
import { Container } from 'reactstrap';
import { CredentialsTable } from './CredentialsTable/CredentialsTable';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { MenuContext } from '../../contexts/MenuContext';
import {
  AllCredentialsContext,
  CredentialsFilters,
} from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import FilterTag from '../../components/FilterTag/FilterTag';
import refreshIcon from '../../assets/refreshIcon.svg';
import { convertToQueryString } from './CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';

export interface TableSpecs {
  totalNumber: number;
  firstPage: string;
  nextPage: string;
  previousPage: string;
  lastPage: string;
  currentPage: number;
  tableSize: number;
}

export function Credentials() {
  useRouteSecurization(['admin', 'operator']);
  const { t } = useTranslation();
  const { filters, tableSpecs, setFilters, loadCredentials } = useContext(
    AllCredentialsContext,
  );
  const { types, email, did, searchableText, status } = filters;
  const { isMenuOpen } = useContext(MenuContext);

  const onRemoveTag = async (filterKey: keyof CredentialsFilters) => {
    const updatedFilter = { ...filters, [filterKey]: '' };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <h1>{t('menu.credentials')}</h1>
      <br></br>
      <SearchBar context={AllCredentialsContext} />
      <div className="filter-list-container">
        {status && (
          <FilterTag
            filter={`${t('table.status')}: ${t(`status.${status}`)}`}
            onClose={() => onRemoveTag('status')}
          />
        )}
        {types && (
          <FilterTag
            filter={`${t('details.type')}: ${types}`}
            onClose={() => onRemoveTag('types')}
          />
        )}
        {email && (
          <FilterTag
            filter={`Email: ${email}`}
            onClose={() => onRemoveTag('email')}
          />
        )}
        {did && (
          <FilterTag
            filter={`DID: ${did}`}
            onClose={() => onRemoveTag('did')}
          />
        )}
        {searchableText && (
          <FilterTag
            filter={`${t('filters.searchText')}: ${searchableText}`}
            onClose={() => onRemoveTag('searchableText')}
          />
        )}
      </div>
      <div className="refresh-icon-container">
        <img
          onClick={async () => {
            const queryParams = convertToQueryString(filters);
            await loadCredentials(
              tableSpecs.tableSize,
              tableSpecs.currentPage,
              queryParams,
            );
          }}
          src={refreshIcon}
          alt="Refresh icon"
          className="refresh-button"
        />
      </div>
      <CredentialsTable />
    </Container>
  );
}
