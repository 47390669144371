import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import { AuditTrailsTable } from './AuditTrailsTable/AuditTrailsTable';
import FilterTag from '../../components/FilterTag/FilterTag';
import { MenuContext } from '../../contexts/MenuContext';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import refreshIcon from '../../assets/refreshIcon.svg';
import { AuditTrailsContext } from '../../contexts/CredentialsProviders/Providers/AuditTrailsContext';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { UserCredentialsFilters } from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import {
  convertToQueryString,
  formatDate,
} from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';

export const AuditTrails = () => {
  const { t } = useTranslation();
  const TAGS_NAMES: Record<string, string> = {
    entityName: t('details.entity'),
    searchableText: t('filters.searchText'),
    action: t('filters.actionName'),
    startDate: t('filters.startDate'),
    endDate: t('filters.endDate'),
    issuanceId: t('details.credentialIssuanceId'),
  };
  const { filters, setFilters, loadCredentials, tableSpecs } =
    useContext(AuditTrailsContext);
  const { isMenuOpen } = useContext(MenuContext);
  useRouteSecurization(['admin']);

  const refreshData = async () => {
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(
      tableSpecs.tableSize,
      tableSpecs.currentPage,
      queryParams,
    );
  };

  const onRemoveTag = async (filterKey: keyof UserCredentialsFilters) => {
    const updatedFilter = {
      ...filters,
      [filterKey]: '',
      entityId: filterKey === 'entityName' ? '' : filters.entityId,
    };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <h1>{t('menu.auditTrails')}</h1>
      <br></br>
      <SearchBar context={AuditTrailsContext} />
      <div className="filter-list-container">
        {Object.entries(filters).map(([key, value]) => {
          if (key === 'entityId') return null;
          const tagValue = key.includes('Date') ? formatDate(value) : value;
          return (
            value && (
              <FilterTag
                key={key}
                filter={`${TAGS_NAMES[key]}: ${tagValue}`}
                onClose={() => onRemoveTag(key as keyof UserCredentialsFilters)}
              />
            )
          );
        })}
      </div>
      <div className="refresh-icon-container">
        <img
          onClick={refreshData}
          src={refreshIcon}
          alt="Refresh icon"
          className="refresh-button"
        />
      </div>
      <AuditTrailsTable />
    </Container>
  );
};
